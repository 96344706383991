import React from "react";
import { Link } from "gatsby";

const Footer = () => {
  return (
    <footer className="bg-dark pt-2">
      <div className="container pt-2">
        <div className="row">
          <div className="col-lg-6">
            <div className="widget widget-light-skin mb-0">
              <h4 className="widget-title">Singapore Office</h4>
              <p className="text-sm text-white opacity-50">
                <i className="fe-icon-map-pin text-white opacity-60" />
                &nbsp;181 bedok North Road, Singapore 460181
              </p>
              <p className="text-sm text-white opacity-50">
                <i className="fe-icon-mail text-white opacity-60" />
                <a
                  href="mailto:contact@tech2reach.com"
                  className="navi-link text-white"
                >
                  &nbsp;contact@tech2reach.com
                </a>
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="widget widget-light-skin mb-0">
              <h4 className="widget-title">Philippine Office</h4>
              <p className="text-sm text-white opacity-50">
                <i className="fe-icon-map-pin text-white opacity-60" />
                &nbsp;212 Sampaguita St. Greendland Sudb. Cainta, Rizal,
                Philippine 1900
              </p>
              <p className="text-sm text-white opacity-50">
                <i className="fe-icon-mail text-white opacity-60" />
                <a
                  href="mailto:jobs@tech2reach.com"
                  className="navi-link text-white"
                >
                  &nbsp;jobs@tech2reach.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div style={{ backgroundColor: "#30363d" }} className="pt-2">
        <div className="container">
          <div className="d-md-flex justify-content-between align-items-center py-4 text-center text-md-left">
            <div className="order-2">
              <Link to="/accountants/" className="footer-link text-white">
                Accountants
              </Link>
              <Link
                to="/customer-service/"
                className="footer-link text-white ml-3"
              >
                Customer Service
              </Link>
              <Link to="/developer/" className="footer-link text-white ml-3">
                Developer
              </Link>
              <Link
                to="/digital-marketing/"
                className="footer-link text-white ml-3"
              >
                Digital Marketing
              </Link>
              <Link to="/blog/" className="footer-link text-white ml-3">
                Blog
              </Link>
              <Link to="/contact-us/" className="footer-link text-white ml-3">
                Contact Us
              </Link>
            </div>
            <p className="m-0 text-sm text-white order-1">
              <span className="opacity-60">
                &copy; copyright 2020 Tech2Reach. Made by{" "}
              </span>{" "}
              <a
                href="https://www.kdcsoftware.com/"
                className="d-inline-block nav-link text-white opacity-60 p-0"
                target="_blank"
                rel="noopener noreferrer"
              >
                KDC Software
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
