import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";

import Header from "./header";
import Footer from "./footer";
import OffCanvas from "./offcanvas";
import ScrollTop from "./scrollTop";

import "../scss/theme.scss";
import "../styles/feather.min.css";

const Layout = ({ children, dark = false, absolute = true }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <Fragment>
          <OffCanvas
            showMobileMenu={showMobileMenu}
            setShowMobileMenu={show => setShowMobileMenu(show)}
          />
          <Header
            siteTitle={data.site.siteMetadata.title}
            setShowMobileMenu={show => setShowMobileMenu(show)}
            dark={dark}
            absolute={absolute}
          />
          {children}
          <Footer />
          <ScrollTop />
          <div className="site-backdrop" />
        </Fragment>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
