import { Link } from "gatsby";
import React from "react";

const Menu = () => (
  <>
    <ul className="navbar-nav d-none d-lg-block">
      <li className="nav-item">
        <Link to="/accountants/" className="nav-link">
          Accountants
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/customer-service/" className="nav-link">
          Customer Service
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/developer/" className="nav-link">
          Developer
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/digital-marketing/" className="nav-link">
          Digital Marketing
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/blog/" className="nav-link">
          Blog
        </Link>
      </li>
    </ul>
    <div>
      <Link
        to="/contact-us/"
        className="btn btn-style-4 btn-primary ml-3 d-none d-xl-inline-block"
      >
        Contact Us
      </Link>
    </div>
  </>
);

export default Menu;
