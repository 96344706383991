import React from "react";
import { StaticQuery, Link, graphql } from "gatsby";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import Menu from "./menu";

const Header = ({ siteTitle, setShowMobileMenu, dark, absolute }) => (
  <StaticQuery
    query={graphql`
      query {
        light: file(relativePath: { eq: "t2r-logo-white.png" }) {
          childImageSharp {
            fixed(width: 127, height: 20) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
        dark: file(relativePath: { eq: "t2r-logo-dark.png" }) {
          childImageSharp {
            fixed(width: 127, height: 20) {
              ...GatsbyImageSharpFixed_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <header
        className={`navbar-wrapper navbar-boxed navbar-simple-ghost ${
          dark ? "navbar-dark" : ""
        }`}
        style={{ position: absolute ? "absolute" : "relative" }}
      >
        <div className="container">
          <div className="d-table-cell align-middle pr-md-3">
            <Link to="/" className="navbar-brand mr-1">
              <Img
                fixed={
                  dark
                    ? data.dark.childImageSharp.fixed
                    : data.light.childImageSharp.fixed
                }
                alt={siteTitle}
                title={siteTitle}
              />
            </Link>
          </div>
          <div className="d-table-cell align-middle w-100 pl-md-3">
            <div className="navbar justify-content-end justify-content-lg-between">
              <Menu />
              <div>
                <ul className="navbar-buttons d-inline-block align-middle">
                  <li className="d-block d-lg-none">
                    <a
                      className={dark ? "text-dark" : "text-light"}
                      href="#mobile-menu"
                      data-toggle="offcanvas"
                      onClick={() => setShowMobileMenu(true)}
                    >
                      <i className="fe-icon-menu" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    )}
  />
);

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
