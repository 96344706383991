import React, { Fragment } from "react";
import { Link } from "gatsby";

const OffCanvas = ({ showMobileMenu, setShowMobileMenu }) => {
  let offCanvasClass =
    "offcanvas-container is-triggered offcanvas-container-reverse";
  if (showMobileMenu) {
    offCanvasClass += " in-view";
  }
  return (
    <Fragment>
      <div className={offCanvasClass} id="mobile-menu">
        <span
          className="offcanvas-close"
          onClick={() => setShowMobileMenu(false)}
          onKeyPress={() => setShowMobileMenu(false)}
          role="button"
          tabIndex={-1}
        >
          <i className="fe-icon-x" />
        </span>
        <div className="px-4 pb-4">
          <h6>Menu</h6>
        </div>
        <div className="offcanvas-top offcanvas-scrollable-area border-top">
          <div className="accordion mobile-menu" id="accordion-menu">
            <div className="card">
              <div className="card-header">
                <Link
                  className="offcanvas-close mobile-menu-link"
                  to="/"
                  onClick={() => setShowMobileMenu(false)}
                >
                  Home
                </Link>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <Link
                  className="offcanvas-close mobile-menu-link"
                  to="/accountants/"
                  onClick={() => setShowMobileMenu(false)}
                >
                  Accountants
                </Link>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <Link
                  className="offcanvas-close mobile-menu-link"
                  to="/customer-service/"
                  onClick={() => setShowMobileMenu(false)}
                >
                  Customer Service
                </Link>
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <Link
                  className="offcanvas-close mobile-menu-link"
                  to="/developer/"
                  onClick={() => setShowMobileMenu(false)}
                >
                  Developer
                </Link>
              </div>
            </div>
            <div className="card border-bottom">
              <div className="card-header">
                <Link
                  className="offcanvas-close mobile-menu-link"
                  to="/digital-marketing/"
                  onClick={() => setShowMobileMenu(false)}
                >
                  Digital Marketing
                </Link>
              </div>
            </div>
            <div className="card border-bottom">
              <div className="card-header">
                <Link
                  className="offcanvas-close mobile-menu-link"
                  to="/blog/"
                  onClick={() => setShowMobileMenu(false)}
                >
                  Blog
                </Link>
              </div>
            </div>
            <div className="card mt-5">
              <div className="card-body">
                <Link
                  to="/contact-us/"
                  className="btn btn-style-4 btn-block btn-primary"
                >
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="offcanvas-footer px-4 pt-3 pb-2 text-center">
          {/* <a className="social-btn sb-style-3 sb-twitter" href="#">
            <i className="socicon-twitter" />
          </a>
          <a className="social-btn sb-style-3 sb-facebook" href="#">
            <i className="socicon-facebook" />
          </a>
          <a className="social-btn sb-style-3 sb-pinterest" href="#">
            <i className="socicon-pinterest" />
          </a>
          <a className="social-btn sb-style-3 sb-instagram" href="#">
            <i className="socicon-instagram" />
          </a> */}
        </div>
      </div>
      <style jsx="true">{`
        .offcanvas-top {
          height: calc(100% - 235px) !important;
          top: 144px !important;
        }
      `}</style>
    </Fragment>
  );
};

export default OffCanvas;
