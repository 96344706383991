import React, { Component } from "react";
import { animateScroll } from "react-scroll";

export default class ScrollTop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transform: 0,
      visible: false
    };
  }
  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll);
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll);
  };

  handleScroll = event => {
    const scrollOffset = 600;
    if (window.scrollY > scrollOffset) {
      this.setState({ visible: true });
    } else {
      this.setState({ visible: false });
    }
  };

  scrollToTop = e => {
    e.preventDefault();
    animateScroll.scrollToTop();
  };

  render() {
    const { visible } = this.state;
    const className = visible
      ? "scroll-to-top-btn visible"
      : "scroll-to-top-btn";
    return (
      <a href="#top" className={className} onClick={this.scrollToTop}>
        <i className="fe-icon-chevron-up" />
      </a>
    );
  }
}
